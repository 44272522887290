import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Store/authContext';


const Logout = () => {

    const navigate = useNavigate();
    // const secure = localStorage.getItem('secure');
    // const  link = localStorage.getItem('url');
    // const url = (secure === 'true') ? 'https://'+link : 'http://'+link;
    
    const [isLoggedOut, setIsLoggedOut] = React.useState(false);
    const authCtx = React.useContext(AuthContext);

    const makeLogout = () => {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Token '+ authCtx.token,
        //     }
        // };

        // fetch(url+'/auth/token/logout/', requestOptions);
        authCtx.logout();
        setIsLoggedOut(true);
    }

    return (
        <React.Fragment>
            {isLoggedOut ? navigate('/') : <span className='header-menu' onClick={makeLogout}><i className='fa fa-sign-out'> Logout</i></span>}
        </React.Fragment>
    )
}

export default Logout
