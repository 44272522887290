import React from "react";
import "../../css/Content.css";
import useAuthFetch from "../../Hooks/useAuthFetch";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ViewRoomSetting = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  const { data, fetchData } = useAuthFetch();

  React.useEffect(() => {
    fetchData("GET", "/mqtt/get_room_settings/" + props.room.id + "/");
  }, [props.room.id, fetchData]);

  return (
    <div className="content-page-authorization">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        message="Copied to clipboard!"
        onClose={() => setOpen(false)}
      />
      <div className="content-heading d-flex justify-content-left">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            props.displayMe(props.room, false);
          }}
        >
          <span>
            &nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;
          </span>
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <h4>{props.room.name} Settings</h4>
      </div>
      <div className="content-box">
        {data !== null && (
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Setting</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Topic:</strong></td>
                  <td>{data.body.topic}</td>
                  <td>
                    <Tooltip title="Copy to clipboard" arrow>
                      <ContentCopyIcon color="primary"
                        onClick={() => handleCopyToClipboard(data.body.topic)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td><strong>Client ID:</strong></td>
                  <td>{data.body.client_id}</td>
                  <td>
                    <Tooltip title="Copy to clipboard" arrow>
                      <ContentCopyIcon color="primary"
                        onClick={() =>
                          handleCopyToClipboard(data.body.client_id)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td><strong>App Key:</strong></td>
                  <td>{data.body.app_key}</td>
                  <td>
                    <Tooltip title="Copy to clipboard" arrow>
                      <ContentCopyIcon color="primary"
                        onClick={() =>
                          handleCopyToClipboard(data.body.app_key)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td><strong>App Secret:</strong></td>
                  <td>{data.body.app_secret}</td>
                  <td>
                    <Tooltip title="Copy to clipboard" arrow>
                      <ContentCopyIcon color="primary"
                        onClick={() =>
                          handleCopyToClipboard(data.body.app_secret)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </td>
                </tr>
                <tr>
                  <td><strong>Device IDs:</strong></td>
                  <td>{data.body.device_ids}</td>
                  <td>
                    <Tooltip title="Copy to clipboard" arrow>
                      <ContentCopyIcon color="primary"
                        onClick={() =>
                          handleCopyToClipboard(data.body.device_ids)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewRoomSetting;
