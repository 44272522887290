import React from 'react';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';

const Fan = (props) => {

  const [speed, setSpeed] = React.useState(Number(props.data.message));

  React.useEffect(() => {
    setSpeed(Number(props.data.message));
}, [props.data.message]);

const handleChange = (event, newValue) => {
  event.stopImmediatePropagation();
  if (typeof newValue === 'number') {
    setSpeed(newValue);
    let state;
    if(newValue === 0){
        state = 1;
    } else {
        state = 0;
    }
    props.handleMessage(props.data.id, state, props.data.command, newValue);
  }
};

  const handleClick = (e, id, state, command, message, type) => {
    e.stopPropagation();
    if(type === 'FAN'){
      if(state === 1){
          message = 0;
      } else {
          message = 4;
      }
    }
    props.handleMessage(id, state, command, message);
  }

  return (
    <>
      <div className='item-box'>
        <center>
          <div className='fanHolder'>
              <img className={(speed === 1) ? 'fan fan-speed-1' : (speed === 2) ? 'fan fan-speed-2' : (speed === 3) ? 'fan fan-speed-3' : (speed === 4) ? 'fan fan-speed-4' :  'fan'} src={require('../../../img/fanFill.png')} alt='' />
          </div>
          <div style={{marginLeft: '20px', marginRight: '20px', marginTop: -1, marginBottom: -15}}>
          <Slider size='small' sx={{}} aria-label="Temperature" value={speed} onChange={handleChange} valueLabelDisplay="auto" shiftStep={1} step={1} marks min={0} max={4} />

          </div>
          <span style={{fontSize: '12px', color: 'gray'}}>Switch is {(props.data.state === 1) ? 'on' : 'off'}</span><br />
          <b>{props.data.name}</b><br />
          <Button variant={(props.data.state === 1) ? 'outlined' : 'contained'} size='small' sx={{marginTop: '5px'}} onClick={(e) => handleClick(e, props.data.id, props.data.state, props.data.command, props.data.message, props.data.type)}>{(props.data.state === 1) ? 'Turn Off' : 'Turn On'}</Button>
          </center>
      </div>
    </>
  )
}

export default Fan