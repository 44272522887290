import React from 'react';
import Login from './Login';
import Register from './Register';
import AuthContext from '../Store/authContext';
import ForgotPassword from './ForgotPassword';

const Account = () => {

    const authCtx = React.useContext(AuthContext);
    const [formType, setFormType] = React.useState('login');

    const setLoginState = (token) => {
        authCtx.login(token)
    }

    const changeForm = (value) => {
        setFormType(value);
    }

    return (
        <div className='account' style={{backgroundColor: '#e3ecfa', position: 'absolute', height: '100%', width: '100%', alignContent: 'center'}}>
            {(formType === 'login') && <Login changeForm={changeForm} setLoginState={setLoginState} />}
            {(formType === 'register') && <Register changeForm={changeForm} />}
            {(formType === 'forgot_password') && <ForgotPassword changeForm={changeForm} />}
        </div>
    )
}

export default Account
