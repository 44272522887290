import React from 'react';
import '../../css/Content.css';
import AddSchedule from './AddSchedule';
import useAuthFetch from '../../Hooks/useAuthFetch';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

const Schedule = () => {

  const {data, isPending, error: fetchError, fetchData} = useAuthFetch();
  const [displayForm, setDisplayForm] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const displayAddForm = () => {
    setDisplayForm(false)
    setRefresh(refresh+1);
  }

  React.useEffect(() => {
      fetchData('GET', '/app/schedule/?page='+currentPage);
  }, [refresh, currentPage, fetchData]);

  const deleteSchedule = (id) => {
    if(window.confirm('Are you sure?')){
      fetchData('DELETE', '/app/schedule/delete/'+id+'?page='+currentPage);
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  React.useEffect(() => {
    if (data != null) {
      setTotalPages(Math.ceil(data.body.count / 10));
    }
  }, [data]);

  const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    const formattedTime = `${String(hour12).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
    return formattedTime;
  };

  return (
    <div className='content-page'>
        <div className='content-heading d-flex justify-content-between'>
            <div>
              {!displayForm ? 
                <div>
                  <button className='btn btn-primary btn-sm' onClick={() => {setDisplayForm(true)}}><i className="fa fa-plus" aria-hidden="true"></i> Add Schedule</button>
                  <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => {setRefresh(refresh+1)}}><span>&nbsp;<i className='fa fa-refresh'></i>&nbsp;</span></button>
                </div> : 
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {setDisplayForm(false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>
              }
            </div>
            <h4>{displayForm ? 'New Schedule' : 'Schedules'}</h4>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div className='content-box'>
          {displayForm ? 
            <AddSchedule displayMe={displayAddForm} /> :
            <div>
              <div className='alert-box'>
                {isPending && <span>Please wait...</span>}
                {fetchError && <span className='text-danger'>Oops! Something went wrong.</span>}
              </div>
              <div style={{overflowX: 'auto'}}>  
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>DESCRIPTION</th>
                      <th>DEVICE ID</th>
                      <th>ACTION</th>
                      <th>REPEAT ON</th>
                      <th>OPTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data !== null) && data.body.results.map((item, index) => {
                      let days = [];
                      if((item.repeat_days !== null) && (item.repeat_days !== '')){
                        days = item.repeat_days.replaceAll("'", "").split(',').map(Number);
                      }
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.info}</td>
                          <td>{item.device}</td>
                          <td>{(item.turn_on === 'true') ? 'Turn On' : 'Turn Off'}</td>
                          <td>
                            <>
                              <Tooltip title="Sunday"><input type="checkbox" id="0" checked={days.includes(0)} /></Tooltip>
                              &nbsp;<Tooltip title="Monday"><input type="checkbox" id="1" checked={days.includes(1)} /></Tooltip>
                              &nbsp;<Tooltip title="Tuesday"><input type="checkbox" id="2" checked={days.includes(2)} /></Tooltip>
                              &nbsp;<Tooltip title="Wednesday"><input type="checkbox" id="3" checked={days.includes(3)} /></Tooltip>
                              &nbsp;<Tooltip title="Thursday"><input type="checkbox" id="4" checked={days.includes(4)} /></Tooltip>
                              &nbsp;<Tooltip title="Friday"><input type="checkbox" id="5" checked={days.includes(5)} /></Tooltip>
                              &nbsp;<Tooltip title="Saturday"><input type="checkbox" id="6" checked={days.includes(6)} /></Tooltip>
                            </><br />{convertTo12HourFormat(item.repeat_time)}
                          </td>
                          <td>
                            <Button variant="outlined" size='small' startIcon={<DeleteIcon />} onClick={() => {deleteSchedule(item.id)}} disabled={item.is_default}>Delete</Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Stack spacing={2}>
                <Pagination count={totalPages} color="primary" value={currentPage} onChange={handlePageChange} />
              </Stack>
            </div>
          }
        </div>
    </div>
  )
}

export default Schedule