import React from 'react';
import useInput from '../../Hooks/useInput';
import useFetch from '../../Hooks/useFetch';
import useAuthFetch from '../../Hooks/useAuthFetch';
import Switch from '@mui/material/Switch';

const AddDevice = (props) => {

    const [alertMsg, setAlertMsg] = React.useState([]);
    const {data:roomData, fetchData} = useAuthFetch();
    const [is_active, setIsActive] = React.useState(true);

    const handleChangeActive = (event) => {
      setIsActive(event.target.checked);
    };

    const {
      value: deviceName,
      isValid: enteredNameIsValid,
      hasError: nameInputHasError,
      valueChangeHandler: nameChangeHandler,
      inputBlurHandler: nameBlurHandler,
    } = useInput(value => value.length <= 15 && value.trim() !== '');
  
    const nameInputClasses = nameInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
  
    const {
      value: deviceType,
      isValid: enteredDeviceTypeIsValid,
      hasError: deviceTypeInputHasError,
      valueChangeHandler: deviceTypeChangeHandler,
      inputBlurHandler: deviceTypeBlurHandler,
    } = useInput(value => value.trim() !== '');
  
    const deviceTypeInputClasses = deviceTypeInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
    
    const {
      value: info,
      isValid: enteredInfoIsValid,
      hasError: infoInputHasError,
      valueChangeHandler: infoChangeHandler,
      inputBlurHandler: infoBlurHandler,
    } = useInput(value => value.length <= 200 && value.trim() !== '');
  
    const infoInputClasses = infoInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';

    const {
        value: roomId,
        isValid: enteredRoomIdIsValid,
        hasError: roomIdInputHasError,
        valueChangeHandler: roomIdChangeHandler,
        inputBlurHandler: roomIdBlurHandler,
      } = useInput(value => value.trim() !== '');
    
      const roomIdInputClasses = roomIdInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
  
    const validForm = enteredNameIsValid && enteredDeviceTypeIsValid && enteredRoomIdIsValid && enteredInfoIsValid;
  
    const postData = {
      name: deviceName,
      type: deviceType,
      room: roomId,
      info: info,
      is_active: is_active
    }

    const {data, isPending, error, submitHandler: submitForm } = useFetch(postData);

    React.useEffect(() => {
      fetchData('GET', '/app/room/');
    }, [fetchData]);
  
    React.useEffect(() => {
      if(isPending !== null && isPending === true){
          setAlertMsg({
              'class': 'primary',
              'message': 'Please wait...'
          });
      }
      if(isPending === false){
          setAlertMsg('');
          if(error !== null){
              if(error === 'Failed to fetch'){
                  setAlertMsg({
                      'class': 'danger',
                      'message': 'Oops! Server not responding please try again.'
                  });
              } else {
                setAlertMsg({
                    'class': 'danger',
                    'message': 'Oops! Something went wrong'
                });
              }
          }
          if(data !== null){
            if(data.status === 201){
              props.displayMe(false);
              setAlertMsg({
                'class': '',
                'message': ''
              });
            }
          }
      }
    }, [data, isPending, error, props, setAlertMsg]);
  
  return (
    <form className='add-form' method='POST' action='/app/device/add/' token='true' onSubmit={submitForm}>
        <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
        <div className="form-group">
            <label htmlFor="deviceName">Device Name</label>
            <input type="text" className={nameInputClasses} id='deviceName' placeholder='Your Device name...' onChange={nameChangeHandler} onBlur={nameBlurHandler} value={deviceName} />
            {nameInputHasError && (
                <span className="text-danger">Please enter device name.</span>
            )}
        </div>
        <div className="form-group">
            <label htmlFor="deviceType">Device Type</label>
            <select name="deviceType" id="deviceType" className={deviceTypeInputClasses} onChange={deviceTypeChangeHandler} onBlur={deviceTypeBlurHandler} value={deviceType}>
                <option value="">Select...</option>
                <option value="BULB">Light</option>
                <option value="FAN">Fan</option>
                <option value="SOCKET">Socket</option>
                <option value="TV">TV</option>
            </select>
            {deviceTypeInputHasError && (
                <span className="text-danger">Please select device type.</span>
            )}
        </div>
        <div className="form-group">
            <label htmlFor="info">Description</label>
            <textarea className={infoInputClasses} id='info' placeholder='Write here...' onChange={infoChangeHandler} onBlur={infoBlurHandler} value={info} />
            {infoInputHasError && (
                <span className="text-danger">Please write description.</span>
            )}
        </div>
        <div className="form-group">
            <label htmlFor="roomId">Select Room</label>
            <select name="roomId" id="roomId" className={roomIdInputClasses} onChange={roomIdChangeHandler} onBlur={roomIdBlurHandler} value={roomId}>
                <option value="">Select...</option>
                {(roomData !== null) && roomData.body.results.map((item, index) => {
                    return (
                        <option key={index} value={item.id}>{item.name}</option>
                    )
                })}  
            </select>
            <span className="text-info">Select Home if you do not want to add it to any room.</span>
            {roomIdInputHasError && (
                <span className="text-danger"><br />Please select room name.</span>
            )}
        </div>
        <div className="form-group">
            Active<Switch checked={is_active} onChange={handleChangeActive} />
            <button type="submit" className={`btn btn-primary btn-sm ${(!validForm && 'disabled')}`} style={{float: 'right'}}>Add Device</button>
        </div>
    </form>
  )
}

export default AddDevice