import React from 'react';
import AuthContext from '../Store/authContext';

const useRequest = () => {
    const secure = localStorage.getItem('secure');
    const  link = localStorage.getItem('url');
    const url = (secure === 'true') ? 'https://'+link : 'http://'+link;
    
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const csrftoken = getCookie('csrftoken');

    const [isPending, setIsPending] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const authCtx = React.useContext(AuthContext);

    const fetchData = React.useCallback(async (method, path, body) => {
        setIsPending(true);
        const requestOptions = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
                'Authorization': 'Token ' + authCtx.token
            },
            body: JSON.stringify(body)
        };
    
        try {
            const response = await fetch(url + path, requestOptions);
    
            if (!response.ok) {
                const errorData = await response.json();
                setError({ status: response.status, data: errorData ? errorData : 'No message' });
                return;
            } else if (response.status === 204 || response.status === 202 || response.status === 201){
                setData({ status: response.status, body: 'Resource accepted.' });
                setError(null);
            } else {
                const data = await response.json();
                setData({ status: response.status, body: data });
                setError(null);
            }    
        } catch (err) {
            setError(err);
            setData(null);
        } finally {
            setIsPending(false);
        }
    }, [csrftoken, authCtx.token, url]);
    
    
    

    return { data, isPending, error, fetchData };
};

export default useRequest;