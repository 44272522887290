import * as React from 'react';
import AddCredential from './AddCredential';
import '../../css/Content.css';
import useAuthFetch from '../../Hooks/useAuthFetch';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Credentials = () => {

  const [open, setOpen] = React.useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        setOpen(false)
      });
  };

  const {data, isPending, error: fetchError, fetchData} = useAuthFetch();

  const [displayForm, setDisplayForm] = React.useState(false);

  const [refresh, setRefresh] = React.useState(0);

  const displayAddForm = () => {
    setDisplayForm(false)
    setRefresh(refresh+1);
  }

  React.useEffect(() => {
      fetchData('GET', '/mqtt/credential/');
  }, [refresh, fetchData]);

  const deleteCredential = (id) => {
    if(window.confirm('Are you sure?')){
      fetchData('DELETE', '/mqtt/credential/delete/'+id);
    }
  }

  return (
    <div className='content-page'>
        <Snackbar open={open} autoHideDuration={2000} message="Copied to clipboard!" onClose={() => setOpen(false)} />
        <div className='content-heading d-flex justify-content-between'>
            <div>
              {!displayForm ? 
                <div>
                  <button className='btn btn-primary btn-sm' onClick={() => {setDisplayForm(true)}}><i className="fa fa-plus" aria-hidden="true"></i> Add Credential</button>
                  <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => {setRefresh(refresh+1)}}><span>&nbsp;<i className='fa fa-refresh'></i>&nbsp;</span></button>
                </div> : 
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {setDisplayForm(false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>
              }
            </div>
            <h4>{displayForm ? 'New Credential' : 'Credentials'}</h4>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div className='content-box'>
          {displayForm ? 
            <AddCredential displayMe={displayAddForm} /> :
            <div>
              <div className='alert-box'>
                {isPending && <span>Please wait...</span>}
                {fetchError && <span className='text-danger'>Oops! Something went wrong.</span>}
              </div>
              <div style={{overflowX: 'auto'}}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>APP_KEY</th>
                      <th>APP_SECRET</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data !== null) && data.body.results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>
                            <span>{item.app_key} </span>
                            <Tooltip title="Copy"><ContentCopyIcon onClick={() => copyToClipboard(item.app_key)} fontSize='small' color='primary' /></Tooltip>
                          </td>
                          <td>
                            <span>{item.app_secret} </span>
                            <Tooltip title="Copy"><ContentCopyIcon onClick={() => copyToClipboard(item.app_secret)} fontSize='small' color='primary' /></Tooltip>
                          </td>
                          <td><Button variant="outlined" size='small' startIcon={<DeleteIcon />} onClick={() => {deleteCredential(item.id)}} disabled={item.is_default}>Delete</Button></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                </div>
            </div>
          }
        </div>
    </div>
  )
}

export default Credentials