import React from 'react';
import useInput from '../../Hooks/useInput';
import useFetch from '../../Hooks/useFetch';

const AddTopic = (props) => {

    const [alertMsg, setAlertMsg] = React.useState([]);
    
    const {
        value: topicName,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
    } = useInput(value => value.length <= 12 && value.trim() !== '' && !/[\/#]/.test(value));

    const nameInputClasses = nameInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
    

    const validForm = enteredNameIsValid;
  
    const postData = {
      name: topicName
    }

    const {data, isPending, error, submitHandler: submitForm } = useFetch(postData);
    
    React.useEffect(() => {
        if(isPending !== null && isPending === true){
            setAlertMsg({
                'class': 'primary',
              'message': 'Please wait...'
          });
      }
      if(isPending === false){
          setAlertMsg('');
          if(error !== null){
              if(error === 'Failed to fetch'){
                  setAlertMsg({
                      'class': 'danger',
                      'message': 'Oops! Server not responding please try again.'
                  });
              } else {
                setAlertMsg({
                    'class': 'danger',
                    'message': 'Oops! Something went wrong'
                });
              }
          }
          if(data !== null){
            if(data.status === 201){
              props.displayMe(false);
              setAlertMsg({
                'class': '',
                'message': ''
              });
            }
          }
      }
    }, [data, isPending, error, setAlertMsg]);
  
  return (
    <form className='add-form' method='POST' action='/mqtt/topic/add/' token='true' onSubmit={submitForm}>
        <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
        <div className="form-group">
            <label htmlFor="topicName">MQTT Topic</label>
            <input type="text" className={nameInputClasses} id='topicName' placeholder='Write MQTT topic...' onChange={nameChangeHandler} onBlur={nameBlurHandler} value={topicName} />
            {nameInputHasError && (
                <span className="text-danger">Please enter valid topic.</span>
            )}
        </div>
        <div className="form-group">
            <button type="submit" className={`btn btn-primary btn-sm ${(!validForm && 'disabled')}`} style={{float: 'right'}}>Add Topic</button>
        </div>
    </form>
  )
}

export default AddTopic