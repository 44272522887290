import React from 'react';
import useInput from '../../Hooks/useInput';
import useFetch from '../../Hooks/useFetch';
import Switch from '@mui/material/Switch';

const AddRoom = (props) => {

    const [alertMsg, setAlertMsg] = React.useState([]);
    const [is_active, setIsActive] = React.useState(true);

    const handleChangeActive = (event) => {
      setIsActive(event.target.checked);
    };

    const {
      value: roomName,
      isValid: enteredNameIsValid,
      hasError: nameInputHasError,
      valueChangeHandler: nameChangeHandler,
      inputBlurHandler: nameBlurHandler,
    } = useInput(value => value.length <= 12 && value.trim() !== '');
  
    const nameInputClasses = nameInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
  
    const {
      value: info,
      isValid: enteredInfoIsValid,
      hasError: infoInputHasError,
      valueChangeHandler: infoChangeHandler,
      inputBlurHandler: infoBlurHandler,
    } = useInput(value => value.length <= 200 && value.trim() !== '');
  
    const infoInputClasses = infoInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
  
    const validForm = enteredNameIsValid && enteredInfoIsValid;
  
    const postData = {
      name: roomName,
      info: info,
      is_active: is_active
    }
    
    const { data, isPending, error, submitHandler: submitForm } = useFetch(postData);
  
    React.useEffect(() => {
      if(isPending !== null && isPending === true){
          setAlertMsg({
              'class': 'primary',
              'message': 'Please wait...'
          });
      }
      if(isPending === false){
          setAlertMsg('');
          if(error !== null){
              if(error === 'Failed to fetch'){
                  setAlertMsg({
                      'class': 'danger',
                      'message': 'Oops! Server not responding please try again.'
                  });
              } else {
                setAlertMsg({
                    'class': 'danger',
                    'message': 'Oops! Something went wrong'
                });
              }
          }
          if(data !== null){
            if(data.status === 201){
              props.displayMe(false);
              setAlertMsg({
                'class': '',
                'message': ''
              });
            }
          }
      }
    }, [props, data, isPending, error, setAlertMsg]);
  
  return (
    <form className='add-form' method='POST' action='/app/room/add/' token='true' onSubmit={submitForm}>
        <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
        <div className="form-group">
            <label htmlFor="roomName">Room Name</label>
            <input type="text" className={nameInputClasses} id='roomName' placeholder='Your room name...' onChange={nameChangeHandler} onBlur={nameBlurHandler} value={roomName} />
            {nameInputHasError && (
                <span className="text-danger">Please enter valid room name.</span>
            )}
        </div>
        <div className="form-group">
            <label htmlFor="info">Description</label>
            <textarea className={infoInputClasses} id='info' placeholder='Write here...' onChange={infoChangeHandler} onBlur={infoBlurHandler} value={info} />
            {infoInputHasError && (
                <span className="text-danger">Please write description.</span>
            )}
        </div>
        <div className="form-group">
            Active<Switch checked={is_active} onChange={handleChangeActive} />
            <button type="submit" className={`btn btn-primary btn-sm ${(!validForm && 'disabled')}`} style={{float: 'right'}}>Add Room</button>
        </div>
    </form>
  )
}

export default AddRoom