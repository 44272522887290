import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';


const DoorLock = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [alertMsg, setAlertMsg] = React.useState('')
  const [displayChangePinForm, setDisplayChangePinForm] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDisplayChangePinForm(false)
    setOpen(false);
    setAlertMsg('');
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOpenAlert(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [openAlert]);

  const handleChangeForm = () => {
    setDisplayChangePinForm(true)
  }

  return (
    <React.Fragment>
        {(Number(props.data.state) === 1) ? 
            <Tooltip title="Door is Open">
                <LockOpenIcon fontSize='large' color='primary' onClick={() => props.handleMessage(props.data.id, props.data.state, 'close_lock', props.data.message)} />
            </Tooltip> : 
            <Tooltip title="Door is Locked">
                <LockIcon fontSize='large' color='primary' onClick={handleClickOpen} />
            </Tooltip>
        }
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            if(displayChangePinForm){
                const pin = formJson.pin;
                const new_pin = formJson.new_pin;
                if(pin === new_pin){
                    props.handleMessage(props.data.id, props.data.state, 'open_lock', pin);
                    handleClose();
                } else {
                    setAlertMsg('Oops! Pin does not match.')
                    setOpenAlert(true);
                }
            } else {
                const pin = formJson.pin;
                if(props.data.message === pin){
                    props.handleMessage(props.data.id, props.data.state, 'open_lock', props.data.message);
                    handleClose();
                }else {
                    setAlertMsg('Oops! Wrong Pin.')
                    setOpenAlert(true);
                }
            }
          },
        }}
      >
        <DialogTitle sx={{pb:0}}>{displayChangePinForm ? 'Change door lock Pin' : 'Enter Pin to unlock door'}</DialogTitle>
        <Collapse in={openAlert}>
            <Stack sx={{ml:3, mr:3}} spacing={1}>
                <span style={{fontSize: "0.75rem", color: "red"}}>{alertMsg}</span>
            </Stack>
        </Collapse>
        {displayChangePinForm ?
            <div>
                <DialogContent>
                    <TextField sx={{mt:0}} autoFocus required margin="dense" id="pin" name="pin" label="Enter new pin" type="number" fullWidth variant="standard" />
                    <TextField sx={{mt:0}} required margin="dense" id="new_pin" name="new_pin" label="Confirm new pin" type="number" fullWidth variant="standard" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type='submit'>Change Pin</Button>
                </DialogActions>
            </div>:
            <div>
                <DialogContent>
                    <TextField sx={{mt:0}} autoFocus required margin="dense" id="pin" name="pin" label="Enter Pin" type="number" fullWidth variant="standard" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangeForm}>Change Pin</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Unlock</Button>
                </DialogActions>
            </div>
        }
      </Dialog>
    </React.Fragment>
  );
}

export default DoorLock