import React from 'react';
import AuthContext from './Store/authContext';
import Logout from './Account/Logout';

const Header = (props) => {

    const authCtx = React.useContext(AuthContext);
    const adminLoginState = authCtx.isLoggedIn;

    const [displayMenuBar, setDisplayMenuBar] = React.useState(false);

    React.useEffect(() => {
        if(window.innerWidth > 412){
            setDisplayMenuBar(false);
        } else {
            setDisplayMenuBar(true);
        }
    },[]);

    return (
        <div>
            <nav className="navbar fixed-top">
            <span className="navbar-brand">{props.brand}</span>
            {!adminLoginState ? <span className='header-menu'><i className='fa fa-sign-in'> Login</i></span> :
                !displayMenuBar ? <Logout /> : 
                    <div className='toggle-icon' onClick={()=>{props.displaySideMenu()}}><i className="fa fa-bars"></i></div>
            }
            </nav>
        </div>
    )
}

export default Header
