import React, { useState, useEffect } from 'react';
import '../../css/Dashboard.css';
import AuthContext from '../../Store/authContext';
import useWebSocket from 'react-use-websocket';
import Room from '../../App/Room';
import Water from '../../App/Device/Water';
import DoorLock from '../../App/Device/DoorLock';
import Tooltip from '@mui/material/Tooltip';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import DoorbellOutlinedIcon from '@mui/icons-material/DoorbellOutlined';

const Dashboard = (props) => {
  
  const secure = localStorage.getItem('secure');
  const  link = localStorage.getItem('url');
  const url = (secure === 'true') ? 'wss://'+link : 'ws://'+link;

  const authCtx = React.useContext(AuthContext);
  const [data, setData] = useState(null);
  const [filterItemData, setFilterByItem] = useState(null);
  const [key, setKey] = useState(0);

  const { sendMessage, lastMessage } = useWebSocket(url+'/ws/?token='+authCtx.token);

  const changeFilter = (event) => {
    setKey(event.target.value);
  }

  useEffect(() => {
    if (lastMessage !== null) {
      if (key >= 0) {
        setData([JSON.parse(lastMessage.data).slice(1)[Number(key)]]);
      } else {
        setData(JSON.parse(lastMessage.data).slice(1));
      }
      setFilterByItem(JSON.parse(lastMessage.data));
    }
  }, [lastMessage, key, setData]);

  const handleMessage = (id, state, command, message) => {
    const msg = {
      'deviceId': id,
      'state': 1 - state,
      'command': command,
      'message': message
    }
    sendMessage(JSON.stringify(msg));
  }

  const handleRoomState = (roomId, state, command) => {
    const msg = {
      'roomId': roomId,
      'state': state,
      'command': command,
    }
    sendMessage(JSON.stringify(msg));
  }

  return (
    <div className='content-page'>
      <div className='content-heading d-flex justify-content-between'>
        <h4>Dashboard</h4>
        <div className='filter-box d-flex'>
          {data !== null && 
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '5px' }}>
              {(filterItemData[0].roomInfo[1].state === 1) ? <Tooltip title="Door Bell is On"><DoorbellIcon fontSize='large' color='primary' onClick={() => handleMessage(filterItemData[0].roomInfo[1].id, filterItemData[0].roomInfo[1].state, 'door_bell', 'ring')} /></Tooltip> : <Tooltip title="Door Bell is Off"><DoorbellOutlinedIcon fontSize='large' color='primary' onClick={() => handleMessage(filterItemData[0].roomInfo[1].id, filterItemData[0].roomInfo[1].state, 'door_bell', 'ring')} /></Tooltip>}
              <DoorLock data={filterItemData[0].roomInfo[0]} handleMessage={(id, state, command, message) => handleMessage(id, state, command, message)} />
              <Water data={filterItemData[0].roomInfo[2]} handleMessage={(id, state, command, message) => handleMessage(id, state, command, message)} /> 
            </div>
          }
          <select name="roomId" id="roomId" className='filter-room' onChange={changeFilter}>
            {/* <option value="-1">Show All</option> */}
            {(filterItemData !== null) && filterItemData.slice(1).map((item, index) => {
              return (
                <option key={index} value={index}>{item.name}</option>
              )
            })}
          </select>
        </div>
      </div>
      {data !== null && data.map((item, index) => {
        if(item){
          return (
            <Room key={index} data={item} handleRoomState={handleRoomState} handleMessage={handleMessage} />
          )
        } else {
          return (
            <div>Oops! Nothing to display.</div>
          )
        }
      })}
    </div>
  )
}

export default Dashboard