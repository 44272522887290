import React from 'react';
import Fan from './Device/Fan';
import General from './Device/General';

const Item = (props) => {

  const type = props.data.type;

  return (
    <>
      {(type === 'FAN') ? 
        <Fan  data={props.data} handleMessage={props.handleMessage} /> 
      :
        <General data={props.data} handleMessage={props.handleMessage} />
      }
    </>
  )
}

export default Item