import React from "react";
import useFetch from "../Hooks/useFetch";
import useInput from "../Hooks/useInput";

const Register = (props) => {
  const secure = localStorage.getItem('secure');
  const  link = localStorage.getItem('url');
  const url = (secure === 'true') ? 'https://'+link : 'http://'+link;

  const [alertMsg, setAlertMsg] = React.useState([]);
  const [slide, setSlide] = React.useState(1);

  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [usernameInputHasError, setUsernameInputHasError] =
    React.useState(false);
  const [validUserName, setValidUserName] = React.useState(false);
  const [emailInputHasError, setEmailInputHasError] = React.useState(false);
  const [validEmail, setValidEmail] = React.useState(false);
  const [passwordsMatched, setPasswordsMatched] = React.useState(true);
  const [agree, setAgree] = React.useState(false);

  const usernameChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  async function usernameBlurHandler() {
    const response = await fetch(
      url + "/auth/checkusername/?username=" + username
    );
    const res = await response.json();
    if (res.allow === true) {
      setUsernameInputHasError(false);
      setValidUserName(true);
    } else {
      setUsernameInputHasError(true);
      setValidUserName(false);
    }
  }

  async function emailBlurHandler() {
    const response = await fetch(url + "/auth/checkemail/?email=" + email);
    const res = await response.json();
    if (res.allow === true) {
      setEmailInputHasError(false);
      setValidEmail(true);
    } else {
      setValidEmail(false);
      setEmailInputHasError(true);
    }
  }
  const usernameInputClasses = usernameInputHasError
    ? "form-control is-invalid"
    : "form-control";
  const emailInputClasses = emailInputHasError
    ? "form-control is-invalid"
    : "form-control";

  const isEmailUserValid = validEmail && validUserName && agree;

  const changeFormType = () => {
    props.changeForm("login");
  };

  const {
    value: enteredFName,
    isValid: enteredFNameIsValid,
    hasError: fNameInputHasError,
    valueChangeHandler: fNameChangeHandler,
    inputBlurHandler: fNameBlurHandler,
    reset: resetFNameInput,
  } = useInput((value) => value.trim() !== "");

  const fNameInputClasses = fNameInputHasError
    ? "form-control is-invalid"
    : "form-control";

  const {
    value: enteredLName,
    isValid: enteredLNameIsValid,
    hasError: lNameInputHasError,
    valueChangeHandler: lNameChangeHandler,
    inputBlurHandler: lNameBlurHandler,
    reset: resetLNameInput,
  } = useInput((value) => value.trim() !== "");

  const lNameInputClasses = lNameInputHasError
    ? "form-control is-invalid"
    : "form-control";

  const isFnameLnameValid = enteredFNameIsValid && enteredLNameIsValid;

  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPasswordInput,
  } = useInput((value) => value.length >= 8);

  const passwordInputClasses = passwordInputHasError
    ? "form-control is-invalid"
    : "form-control";

  const {
    value: enteredCnfPassword,
    isValid: enteredCnfPasswordIsValid,
    hasError: cnfPasswordInputHasError,
    isTouched: isCnfPasswordTouched,
    valueChangeHandler: cnfPasswordChangeHandler,
    inputBlurHandler: cnfPasswordBlurHandler,
    reset: resetCnfPasswordInput,
  } = useInput((value) => value.trim() !== "");

  const cnfPasswordInputClasses = cnfPasswordInputHasError
    ? "form-control is-invalid"
    : "form-control";

  React.useEffect(() => {
    if (isCnfPasswordTouched) {
      if (enteredPassword !== enteredCnfPassword) {
        setPasswordsMatched(false);
      } else {
        setPasswordsMatched(true);
      }
    }
  }, [isCnfPasswordTouched, enteredPassword, enteredCnfPassword]);

  const validForm =
    enteredPasswordIsValid && enteredCnfPasswordIsValid && passwordsMatched;

  // Submit Form
  const postData = {
    username: username,
    email: email,
    first_name: enteredFName,
    last_name: enteredLName,
    password: enteredPassword,
  };

  const {
    data,
    isPending,
    error,
    submitHandler: submitRegister,
  } = useFetch(postData);
  React.useEffect(() => {
    if (isPending !== null && isPending === true) {
      setAlertMsg({
        class: "primary",
        message: "Please wait...",
      });
    }
    if (isPending === false) {
      setAlertMsg("");
      if (error !== null) {
        if (error === "400") {
          setAlertMsg({
            class: "danger",
            message: "Password is not valid.",
          });
        }
        if (error === "Failed to fetch") {
          setAlertMsg({
            class: "danger",
            message: "Oops! Server not responding please try again.",
          });
        }
      }
      if (data !== null) {
        setAlertMsg({
          class: "success",
          message:
            "Registered successfully.<br />Check your email to verify your account.",
        });
        setUsername("");
        setEmail("");
        setValidEmail(false);
        setValidUserName(false);
        resetFNameInput();
        resetLNameInput();
        resetPasswordInput();
        resetCnfPasswordInput();
        setSlide(1);
      }
    }
  }, [data, isPending, error, setAlertMsg]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <div className="banner">
            <center>
              <img
                src={require("../../img/logo.png")}
                alt="logo"
                style={{ width: "40%", height: "auto" }}
              />
              <h1>SmartHome</h1>
              <h6>Control your home with smart switches.</h6>
            </center>
          </div>
        </div>
        <div className="col-sm-4">
          <form
            className="account-form"
            autoComplete="off"
            method="POST"
            action="/auth/users/"
            token="false"
            onSubmit={submitRegister}
          >
            <h2 className="">Register</h2>
            <p className="my-2">
              Already have an account?{" "}
              <span
                className="text-primary account-link"
                onClick={changeFormType}
              >
                Login
              </span>
            </p>
            <span
              className={`text-${alertMsg.class}`}
              dangerouslySetInnerHTML={{ __html: alertMsg.message }}
            ></span>
            {slide === 1 && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Username:</label>
                  <input
                    type="username"
                    autoComplete="new-username"
                    className={usernameInputClasses}
                    placeholder="Enter username"
                    id="username"
                    onChange={usernameChangeHandler}
                    onBlur={usernameBlurHandler}
                    value={username}
                    onKeyUp={() => setValidUserName(false)}
                  />
                  {usernameInputHasError && (
                    <span className="text-danger">
                      Either username already taken or not valid.
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address:</label>
                  <input
                    type="email"
                    autoComplete="new-email"
                    className={emailInputClasses}
                    placeholder="Enter email"
                    id="email"
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    value={email}
                    onKeyUp={() => setValidEmail(false)}
                  />
                  {emailInputHasError && (
                    <span className="text-danger">
                      Either email already taken or not valid.
                    </span>
                  )}
                </div>
                <p className="text-info">
                  * Username and email must be unique.
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="form-group form-check">
                    <label className="form-check-label">
                      <input className="form-check-input" type="checkbox" value={agree} onClick={() => setAgree(!agree)} />Agree to <a href="/privacy/">Privacy</a> & <a href="/terms/">Terms</a>
                    </label>
                  </div>
                  <button
                    className="btn btn-primary my-2"
                    onClick={() => setSlide(2)}
                    disabled={!isEmailUserValid}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
            {slide === 2 && (
              <div>
                <div className="form-group">
                  <label htmlFor="fName">First Name:</label>
                  <input
                    type="text"
                    className={fNameInputClasses}
                    placeholder="First Name"
                    id="fName"
                    onChange={fNameChangeHandler}
                    onBlur={fNameBlurHandler}
                    value={enteredFName}
                  />
                  {fNameInputHasError && (
                    <span className="text-danger">First Name is required.</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="lName">Last Name:</label>
                  <input
                    type="text"
                    className={lNameInputClasses}
                    placeholder="Last Name"
                    id="lName"
                    onChange={lNameChangeHandler}
                    onBlur={lNameBlurHandler}
                    value={enteredLName}
                  />
                  {lNameInputHasError && (
                    <span className="text-danger">Last Name is required.</span>
                  )}
                </div>
                <p className="text-info">
                  * Hang in there, just one more step to go!
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="btn btn-primary my-2"
                    onClick={() => setSlide(1)}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-primary my-2"
                    onClick={() => setSlide(3)}
                    disabled={!isFnameLnameValid}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
            {slide === 3 && (
              <div>
                <div className="form-group">
                  <label htmlFor="pwd">Password:</label>
                  <input
                    type="password"
                    autoComplete="new-password"
                    className={passwordInputClasses}
                    placeholder="Enter password"
                    id="pwd"
                    onChange={passwordChangeHandler}
                    onBlur={passwordBlurHandler}
                    value={enteredPassword}
                  />
                  {passwordInputHasError && (
                    <span className="text-danger">
                      Valid password is required.
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="cnfPassword">Confirm Password:</label>
                  <input
                    type="password"
                    className={cnfPasswordInputClasses}
                    placeholder="Confirm Password"
                    id="cnfPassword"
                    onChange={cnfPasswordChangeHandler}
                    onBlur={cnfPasswordBlurHandler}
                    value={enteredCnfPassword}
                  />
                  {cnfPasswordInputHasError ? (
                    <span className="text-danger">
                      Confirm Password is required.
                    </span>
                  ) : (
                    !passwordsMatched && (
                      <span className="text-danger">
                        Passwords not matched.
                      </span>
                    )
                  )}
                </div>
                <p className="text-info">* Please enter a strong password.</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="btn btn-primary my-2"
                    onClick={() => setSlide(2)}
                  >
                    Prev
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary my-2"
                    disabled={!validForm || isPending}
                  >
                    Register
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
