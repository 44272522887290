import React from 'react';
import AuthContext from '../Store/authContext';

const Sidemenu = (props) => {

    const [collapsedSidebar, setCollapsedSidebar] = React.useState(false);
    
    const authCtx = React.useContext(AuthContext);

    const makeLogout = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Token '+ authCtx.token,
            }
        };

        fetch('http://127.0.0.1:8000/auth/token/logout/', requestOptions);
        authCtx.logout();
    }

    React.useEffect(() => {
        if(window.innerWidth > 412){
            setCollapsedSidebar(false);
        } else {
            setCollapsedSidebar(true);
        }
    },[]);

    const hideShowSideBar = () => {
        setCollapsedSidebar(!collapsedSidebar)
    }

  return (
        <div className={!props.displaySideMenu ? 'hide-side-menu' : collapsedSidebar ? 'side-menu-box-half' : 'side-menu-box-full'}>
        <div className='row'>
        <div className={`menu-row d-flex ${(props.activeTab === 'Dashboard') && ' active-tab'}`} onClick={()=>{props.tabSelected('Dashboard')}}>
            <div className='side-menu-icon'><i className='fa fa-dashboard'></i></div>
            <div className='side-menu-name'>Dashboard</div>
        </div>
        <div className={`menu-row d-flex ${(props.activeTab === 'Credentials') && ' active-tab'}`} onClick={()=>{props.tabSelected('Credentials')}}>
            <div className='side-menu-icon'><i className='fa fa-lock'></i></div>
            <div className='side-menu-name'>Credentials</div>
        </div>
        <div className={`menu-row d-flex ${(props.activeTab === 'Rooms') && ' active-tab'}`} onClick={()=>{props.tabSelected('Rooms')}}>
            <div className='side-menu-icon'><i className='fa fa-home'></i></div>
            <div className='side-menu-name'>Rooms</div>
        </div>
        <div className={`menu-row d-flex ${(props.activeTab === 'Devices') && ' active-tab'}`} onClick={()=>{props.tabSelected('Devices')}}>
            <div className='side-menu-icon'><i className='fa fa-microchip'></i></div>
            <div className='side-menu-name'>Devices</div>
        </div>
        <div className={`menu-row d-flex ${(props.activeTab === 'Schedule') && ' active-tab'}`} onClick={()=>{props.tabSelected('Schedule')}}>
            <div className='side-menu-icon'><i className='fa fa-calendar'></i></div>
            <div className='side-menu-name'>Schedule</div>
        </div>
        <div className={`menu-row d-flex ${(props.activeTab === 'Topics') && ' active-tab'}`} onClick={()=>{props.tabSelected('Topics')}}>
            <div className='side-menu-icon'><i className='fa fa-exchange'></i></div>
            <div className='side-menu-name'>Topics</div>
        </div>
        {/* <div className={`menu-row d-flex ${(props.activeTab === 'Subscription') && ' active-tab'}`} onClick={()=>{props.tabSelected('Subscription')}}>
            <div className='side-menu-icon'><i className='fa fa-inr'></i></div>
            <div className='side-menu-name'>Subscription</div>
        </div> */}
        <div className={`menu-row d-flex ${(props.activeTab === 'Profile') && ' active-tab'}`} onClick={()=>{props.tabSelected('Profile')}}>
            <div className='side-menu-icon'><i className='fa fa-user'></i></div>
            <div className='side-menu-name'>{props.username}</div>
        </div>
        <div className='menu-row d-flex' onClick={makeLogout}>
            <div className='side-menu-icon'><i className='fa fa-sign-out'></i></div>
            <div className='side-menu-name'>Logout</div>
        </div>
        <div className='menu-row hide-menu-button d-flex' onClick={hideShowSideBar}>
            <div className='side-menu-icon'>{collapsedSidebar ? <i className='fa fa-angle-double-right'></i> : <i className='fa fa-angle-double-left'></i>}</div>
            <div className='side-menu-name'> &nbsp;Collapse Sidebar</div>
        </div>
        </div>
    </div>
  )
}

export default Sidemenu