import React from 'react';
import Item from './Item';
import DoorLock from './Device/DoorLock';
import Tooltip from '@mui/material/Tooltip';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const Room = (props) => {

  const [roomState, setRoomState] = React.useState(0);

  const changeRoomState = (roomId) => {
    setRoomState(1-roomState);
    props.handleRoomState(roomId, roomState, 'change_room_state');
  }

  return (
    <div className='room-box'>
      <div className='room-name-box d-flex justify-content-between'>
        <p style={{fontSize: '20px'}}>{props.data.name} </p>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tooltip title={(roomState === 1) ? "Turn On" : "Turn Off"}>
              <PowerSettingsNewIcon fontSize='large' color='primary' onClick={() => changeRoomState(props.data.id)} />
          </Tooltip>
          {/* <span style={{color:'gray'}}>{props.data.roomInfo.length} {(props.data.roomInfo.length <= 1) ? 'device' : 'devices'}</span> */}
          <DoorLock data={props.data.roomInfo[0]} handleMessage={props.handleMessage} /> 
        </div>
      </div>
      <div className='room-content'>
        {props.data.roomInfo.slice(1).map((val, index) => {
          return (
            <Item key={index} data={val} handleMessage={props.handleMessage} />
          )
        })}
      </div>
    </div>
  )
}

export default Room