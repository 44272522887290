import React from 'react';
import Button from '@mui/material/Button';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TvTwoToneIcon from '@mui/icons-material/TvTwoTone';

const General = (props) => {   

  const handleClick = (e, id, state, command, message, type) => {
    e.stopPropagation();
    props.handleMessage(id, state, command, message);
  }

  return (
    <>
      <div className='item-box' onClick={() => props.setOpen(true)}>
          {(props.data.type === 'BULB') && <LightbulbRoundedIcon sx={{ fontSize: 90 , color: (props.data.state === 1) ? '#f9a825' : '#9e9e9e'}} /> }
          {(props.data.type === 'SOCKET') && <PowerSettingsNewIcon sx={{ fontSize: 90 , color: (props.data.state === 1) ? 'green' : '#9e9e9e'}} />}
          {(props.data.type === 'TV') && <TvTwoToneIcon sx={{ fontSize: 90 , color: (props.data.state === 1) ? '#27a357' : '#9e9e9e'}} />}
          <span style={{fontSize: '12px', color: 'gray'}}>Switch is {(props.data.state === 1) ? 'on' : 'off'}</span><br />
          <b>{props.data.name}</b><br />
          <Button variant={(props.data.state === 1) ? 'outlined' : 'contained'} size='small' sx={{marginTop: '5px'}} onClick={(e) => handleClick(e, props.data.id, props.data.state, props.data.command, props.data.message, props.data.type)}>{(props.data.state === 1) ? 'Turn Off' : 'Turn On'}</Button>
      </div>
    </>
  )
}

export default General