import React from 'react';
import useInput from '../../Hooks/useInput';
import useFetch from '../../Hooks/useFetch';
import Switch from '@mui/material/Switch';
import useAuthFetch from '../../Hooks/useAuthFetch';

const AddAuthorization = (props) => {

    const [alertMsg, setAlertMsg] = React.useState([]);
    const [retain, setRetain] = React.useState(false);
    const [hideRetainOption, setHideRetainOption] = React.useState(true);
    const [hidePublishOption, setHidePublishOption] = React.useState(false);
    const {data:credential, fetchData:fetchCredential} = useAuthFetch();

    
    React.useEffect(() => {
        fetchCredential('GET', '/mqtt/credential/');
    }, [fetchCredential]);

    const handleChangeRetain = (event) => {
        setRetain(event.target.checked);
    };
    
    const {
        value: action,
        isValid: enteredActionIsValid,
        hasError: actionInputHasError,
        valueChangeHandler: actionChangeHandler,
        inputBlurHandler: actionBlurHandler,
    } = useInput(value => value.length <= 9 && value.trim() !== '');
    const actionInputClasses = actionInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';

    const {
        value: cred,
        isValid: enteredCredIsValid,
        hasError: credInputHasError,
        valueChangeHandler: credChangeHandler,
        inputBlurHandler: credBlurHandler,
    } = useInput(value => value.length <= 1 && value.trim() !== '');
    const credInputClasses = credInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
    
    const validForm = enteredActionIsValid && enteredCredIsValid;
  
    const postData = {
      topic: props.topic.id,
      action: action,
      credential: cred,
      retain: retain
    }

    const {data, isPending, error, submitHandler: submitForm } = useFetch(postData);
    
    React.useEffect(() => {
        if(isPending !== null && isPending === true){
            setAlertMsg({
                'class': 'primary',
              'message': 'Please wait...'
          });
      }
      if(isPending === false){
          setAlertMsg('');
          if(error !== null){
              if(error === 'Failed to fetch'){
                  setAlertMsg({
                      'class': 'danger',
                      'message': 'Oops! Server not responding please try again.'
                  });
              } else {
                setAlertMsg({
                    'class': 'danger',
                    'message': 'Oops! Something went wrong'
                });
              }
          }
          if(data !== null){
            if(data.status === 201){
              props.displayMe(false);
              setAlertMsg({
                'class': '',
                'message': ''
              });
            }
          }
      }
    }, [data, isPending, error, setAlertMsg]);

    React.useEffect(() => {
        if (props.topic.name.includes('#')) {
            setHidePublishOption(true);
        } else {
            setHidePublishOption(false);
        }
        if (action === 'publish'){
            setHideRetainOption(false);
        } else {
            setHideRetainOption(true);
            setRetain(false);
        }
    }, [action, props]);
  
  return (
    <form className='add-form' method='POST' action='/mqtt/authz/add/' token='true' onSubmit={submitForm}>
        <h5 style={{color: 'green', userSelect: 'text'}}>{props.topic.name}</h5>
        <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
        <div className="form-group">
            <label htmlFor="credId">Select Credential</label>
            <select name="credId" id="credId" className={credInputClasses} onChange={credChangeHandler} onBlur={credBlurHandler} value={cred}>
                <option value="">Select...</option>
                {(credential !== null) && credential.body.results.map((item, index) => {
                    return (
                        <option key={index} value={item.id}>{item.name}</option>
                    )
                })}  
            </select>
            {credInputHasError && (
                <span className="text-danger"><br />Please select Credential.</span>
            )}
        </div>
        <div className="form-group">
            <label htmlFor="action">Action</label>
            <select name="action" id="action" className={actionInputClasses} onChange={actionChangeHandler} onBlur={actionBlurHandler} value={action}>
                <option value="">Select...</option>
                {!hidePublishOption && <option value="publish">Publish</option>}
                <option value="subscribe">Subscribe</option>
            </select>
            {actionInputHasError && (
                <span className="text-danger">Please select action</span>
            )}
        </div>
        <div className="form-group">
            {!hideRetainOption && <div>Retain<Switch checked={retain} onChange={handleChangeRetain} /></div>}
            <button type="submit" className={`btn btn-primary btn-sm ${(!validForm && 'disabled')}`} style={{float: 'right'}}>Set Authorization</button>
        </div>
    </form>
  )
}

export default AddAuthorization