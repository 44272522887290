import React from 'react';
import '../../css/Content.css';
import AddDevice from './AddDevice';
import useAuthFetch from '../../Hooks/useAuthFetch';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Authorization from './Authorization';

const Devices = () => {

  const {data, isPending, error: fetchError, fetchData} = useAuthFetch();
  const {data:topicData, fetchData:fetchTopic} = useAuthFetch();
  const[pageData, setPageData] = React.useState([]);
  const[filterData, setFilterData] = React.useState([]);
  const [key, setKey] = React.useState(-1);
  const [page, setPage] = React.useState(1);
  const [selectedTopic, setSelectedTopic] = React.useState([]);
  const [displayAuthorization, setDisplayAuthorization] = React.useState(false);

  
  const handleDisplayAuthorization = (topic, display) => {
    if (topic.username !== undefined) {
      const tp = 'eye3network/'+topic.username+'/'+topic.roomName+'/'+topic.id;
      setSelectedTopic([]);
      fetchTopic('GET', '/mqtt/get_topic/?name='+tp);
    }
    setDisplayAuthorization(display)
  }

  React.useEffect(() => {
    if(topicData !== null){
      setSelectedTopic(topicData.body);
      setDisplayAuthorization(true);
    }
  }, [topicData]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const itemsPerPage = 10;
  const currentItems = filterData.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const [displayForm, setDisplayForm] = React.useState(false);

  const [refresh, setRefresh] = React.useState(0);

  const displayAddForm = () => {
    setDisplayForm(false)
    setRefresh(refresh+1);
  }

  React.useEffect(() => {
      fetchData('GET', '/app/devices/');
  }, [refresh, fetchData]);

  React.useEffect(() => {
    if(data != null){
      const combinedItems = data.body.results.flatMap(room =>
        room.roomInfo.map(item => ({
            ...item,
            roomName: room.name
        }))
      );
      setPageData(combinedItems);
    }
  }, [data]);

  React.useEffect(() => {
      const filterItems = pageData.filter(item => item.room === key) 
      if(filterItems.length !== 0){
        setFilterData(filterItems);
      } else {
        setFilterData(pageData);
      }
  }, [pageData, key]);

  const deleteDevice = (deviceId) => {
    if(window.confirm('Are you sure?')){
      fetchData('DELETE', '/app/device/delete/'+deviceId);
    }
  }

  const changeFilter = (event) => {
    const key = Number(event.target.value)
    setKey(key);
  }

  return (
    <div className='content-page'>
        {!displayAuthorization && <div className='content-heading d-flex justify-content-between'>
            <div>
              {!displayForm ? 
                <div>
                  <button className='btn btn-primary btn-sm' onClick={() => {setDisplayForm(true)}}><i className="fa fa-plus" aria-hidden="true"></i> Add Device</button>
                  <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => {setRefresh(refresh+1)}}><span>&nbsp;<i className='fa fa-refresh'></i>&nbsp;</span></button>
                </div> : 
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {setDisplayForm(false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>
                }
            </div>
            <h4>{displayForm ? 'New Device' : 'Devices'}</h4>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        }
        <div className='content-box'>
          {displayAuthorization ? <Authorization topic={selectedTopic} displayMe={(topic, display) => handleDisplayAuthorization(topic, display)} /> : displayForm ? 
            <AddDevice roomData={data} displayMe={displayAddForm} /> :
            <div>
              <div className='filter-box d-flex'>
                <b htmlFor='roomId'>Select Room</b>&nbsp;&nbsp;
                <select name="roomId" id="roomId" className='filter-room' onChange={changeFilter}>
                  <option value="-1">Show All</option>
                  {(data !== null) && data.body.results.map((item, index) => {
                      return (
                          <option key={index} value={item.id}>{item.name}</option>
                      )
                  })}  
                </select>
              </div>
              <div className='alert-box'>
                {isPending && <span>Please wait...</span>}
                {fetchError && <span className='text-danger'>Oops! Something went wrong.</span>}
              </div> 
              <div style={{overflowX: 'auto'}}>             
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DEVICE</th>
                      <th>TYPE</th>
                      <th>DESCRIPTION</th>
                      <th>ROOM</th>
                      <th>AUTHORIZE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems && currentItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.type}</td>
                          <td>{item.info}</td>
                          <td>{item.roomName}</td>
                          <td><Button variant="outlined" size='small' onClick={() => handleDisplayAuthorization(item, false)}>Check</Button></td>
                          <td><Button variant="outlined" size='small' startIcon={<DeleteIcon />} onClick={() => {deleteDevice(item.id)}} disabled={item.is_default}>Delete</Button></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Stack spacing={2}>
                <Pagination count={Math.ceil(filterData.length / itemsPerPage)} page={page} onChange={handleChangePage} />
              </Stack>
            </div>
          }
        </div>
    </div>
  )
}

export default Devices