import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import Account from './components/Account/Account';
import AuthContext from './components/Store/authContext';
import Header from './components/Header';
import useAuthFetch from './components/Hooks/useAuthFetch';
import Admin from './components/Admin/Admin';

function App() {

  const navigate = useNavigate();

  const url = 'eye3network.in';
  const secure = true;

  // const url = '127.0.0.1:8000'
  // const secure = false;

  const authCtx = React.useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  
  const {data: adminInfo, isPending, error: adminError, fetchData} = useAuthFetch();
  
  const [diplaySideMenuBar, setDisplaySideMenuBar] = React.useState(true);

  React.useEffect(() => {
      if(window.innerWidth > 412){
        setDisplaySideMenuBar(true);
      } else {
        setDisplaySideMenuBar(false);
      }
      localStorage.setItem('url', url);
      localStorage.setItem('secure', secure);
  },[secure, url]);

  React.useEffect(() => {
    if(isLoggedIn){
      fetchData('GET', '/app/get_app');
    }
  }, [isLoggedIn, fetchData]);

  if(adminError){
    authCtx.logout();
    navigate('/');
  }

  const displaySideMenu = () => {
    setDisplaySideMenuBar(!diplaySideMenuBar);
  }

  return (
      <div className='body'>
        {isLoggedIn && (isPending === false) && (adminError === null) && (adminInfo !== null) &&
          <div>
            <Header brand='SmartHome' displaySideMenu={displaySideMenu} />
            <Admin displaySideMenu={diplaySideMenuBar} username={adminInfo.body.first_name} app_data={adminInfo.body} />
          </div>
        }
        {!isLoggedIn && <Account />}
      </div>
  );
}

export default App;
