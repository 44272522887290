import React from 'react';
import '../../css/Content.css';
import AddAuthorization from './AddAuthorization';
import useAuthFetch from '../../Hooks/useAuthFetch';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Authorization = (props) => {

  const [open, setOpen] = React.useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        setOpen(false)
      });
  };

  const {data, isPending, error: fetchError, fetchData} = useAuthFetch();
  const [displayForm, setDisplayForm] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const displayAddForm = () => {
    setDisplayForm(false)
    setRefresh(refresh+1);
  }

  React.useEffect(() => {
      fetchData('GET', '/mqtt/authz/?topic='+props.topic.id+'&page='+currentPage);
  }, [refresh, props.topic.id, currentPage, fetchData]);

  const deleteAuthorization = (id) => {
    if(window.confirm('Are you sure?')){
      fetchData('DELETE', '/mqtt/authz/delete/'+id+'?topic='+props.topic.id+'&page='+currentPage);
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  React.useEffect(() => {
    if (data != null) {
      console.log(data)
      setTotalPages(Math.ceil(data.body.count / 10));
    }
  }, [data]);

  return (
    <div className='content-page-authorization'>
        <Snackbar open={open} autoHideDuration={2000} message="Copied to clipboard!" onClose={() => setOpen(false)} />
        <div className='content-heading d-flex justify-content-between'>
            <div>
              {!displayForm ? 
                <div>
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {props.displayMe(props.topic, false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>&nbsp;&nbsp;
                  <button className='btn btn-primary btn-sm' onClick={() => {setDisplayForm(true)}}><i className="fa fa-plus" aria-hidden="true"></i> Add Authorization</button>
                  <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => {setRefresh(refresh+1)}}><span>&nbsp;<i className='fa fa-refresh'></i>&nbsp;</span></button>
                </div> : 
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {setDisplayForm(false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>
              }
            </div>
            <h4>{displayForm ? 'New Authorization' : 'Authorizations'}</h4>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div className='content-box'>
          {displayForm ? 
            <AddAuthorization displayMe={displayAddForm} topic={props.topic} /> :
            <div>
              <h5 style={{color: 'green', userSelect: 'text', marginTop: '5px'}}>
                <span>{props.topic.name} </span>
                <Tooltip title="Copy"><ContentCopyIcon onClick={() => copyToClipboard(props.topic.name)} fontSize='small' color='primary' /></Tooltip>
              </h5>
              <div className='alert-box'>
                {isPending && <span>Please wait...</span>}
                {fetchError && <span className='text-danger'>Oops! Something went wrong.</span>}
              </div>
              <div style={{overflowX: 'auto'}}>  
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>CLIENT ID</th>
                      <th>CREDENTIAL</th>
                      <th>ACTION</th>
                      <th>QOS</th>
                      <th>RETAIN</th>
                      <th>OPTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data !== null) && data.body.results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span>{item.client_id} </span>
                            <Tooltip title="Copy"><ContentCopyIcon onClick={() => copyToClipboard(item.client_id)} fontSize='small' color='primary' /></Tooltip>
                          </td>
                          <td>{item.credential_name}</td>
                          <td>{item.action}</td>
                          <td>{item.qos}</td>
                          <td>{item.retain ? <CheckIcon color='primary' /> : <ClearIcon color='warning' />}</td>
                          <td><Button variant="outlined" size='small' startIcon={<DeleteIcon />} onClick={() => {deleteAuthorization(item.id)}} disabled={item.is_default}>Delete</Button></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Stack spacing={2}>
                <Pagination count={totalPages} color="primary" value={currentPage} onChange={handlePageChange} />
              </Stack>
            </div>
          }
        </div>
    </div>
  )
}

export default Authorization