import React from 'react';
import '../../css/Content.css';
import AddTopic from './AddTopic';
import Authorization from './Authorization';
import useAuthFetch from '../../Hooks/useAuthFetch';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const Topics = () => {

  const {data, isPending, error: fetchError, fetchData} = useAuthFetch();
  const [displayForm, setDisplayForm] = React.useState(false);
  const [displayAuthorization, setDisplayAuthorization] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [selectedTopic, setSelectedTopic] = React.useState([]);

  const displayAddForm = () => {
    setDisplayForm(false);
    setRefresh(refresh+1);
  }

  const handleDisplayAuthorization = (topic, display) => {
    setSelectedTopic(topic);
    setDisplayAuthorization(display);
  }

  React.useEffect(() => {
      fetchData('GET', '/mqtt/topic/?page='+currentPage);
  }, [refresh, currentPage, fetchData]);

  const deleteTopic = (id) => {
    if(window.confirm('Are you sure?')){
      fetchData('DELETE', '/mqtt/topic/delete/'+id+'?page='+currentPage);
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  React.useEffect(() => {
    if (data != null) {
      setTotalPages(Math.ceil(data.body.count / 10));
    }
  }, [data]);


  return (
    <div className='content-page'>
        {!displayAuthorization && <div className='content-heading d-flex justify-content-between'>
            <div>
              {!displayForm ? 
                <div>
                  <button className='btn btn-primary btn-sm' onClick={() => {setDisplayForm(true)}}><i className="fa fa-plus" aria-hidden="true"></i> Add Topic</button>
                  <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => {setRefresh(refresh+1)}}><span>&nbsp;<i className='fa fa-refresh'></i>&nbsp;</span></button>
                </div> : 
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {setDisplayForm(false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>
              }
            </div>
            <h4>{displayForm ? 'New Topic' : 'Topics'}</h4>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        }
        <div className='content-box'>
          {displayAuthorization ? 
            <Authorization topic={selectedTopic} displayMe={(topic, display) => handleDisplayAuthorization(topic, display)} /> : displayForm ? 
            <AddTopic displayMe={displayAddForm} /> :
            <div>
              <div className='alert-box'>
                {isPending && <span>Please wait...</span>}
                {fetchError && <span className='text-danger'>Oops! Something went wrong.</span>}
              </div>
              <div style={{overflowX: 'auto'}}>  
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>TOPIC</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data !== null) && data.body.results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{cursor: 'pointer'}} onClick={() => handleDisplayAuthorization(item, true)}>{item.name}</td>
                          <td>
                            <Button variant="outlined" size='small' startIcon={<DeleteIcon />} onClick={() => {deleteTopic(item.id)}} disabled={item.is_default}>Delete</Button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Stack spacing={2}>
                <Pagination count={totalPages} color="primary" value={currentPage} onChange={handlePageChange} />
              </Stack>
            </div>
          }
        </div>
    </div>
  )
}

export default Topics