import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';

const Water = (props) => { 

    const water_level = props.data.message;
    const number = /^\d+$/.test(water_level) && parseFloat(water_level) >= 0 && parseFloat(water_level) <= 100;


    return (
        <div>
            {number &&
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {(props.data.state === 1) ? 
                    <Tooltip title="Motor is On">
                        <SettingsPowerIcon fontSize='large' color='primary'onClick={() => props.handleMessage(props.data.id, props.data.state, 'turn_off_pump', water_level)} />
                    </Tooltip> : 
                    <Tooltip title="Motor is Off">
                        <PowerSettingsNewIcon fontSize='large' color='primary'onClick={() => props.handleMessage(props.data.id, props.data.state, 'turn_on_pump', water_level)} />
                    </Tooltip>
                }
                <Tooltip title="Water Level">
                    <div>
                        {(water_level <= 10) && <Battery0BarIcon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 11) && (water_level <= 25) && <Battery20Icon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 26) && (water_level <= 40) && <Battery30Icon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 41) && (water_level <= 55) && <Battery50Icon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 56) && (water_level <= 70) && <Battery60Icon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 71) && (water_level <= 85) && <Battery80Icon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 86) && (water_level <= 94) && <Battery90Icon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        {(water_level >= 95) && <BatteryFullIcon fontSize='large' color='primary' sx={{ mr: -1}} />}
                        <span style={{ fontSize: '12px', color: '#1976d2', fontWeight: 'bold' }}>{water_level}%</span>
                    </div>
                </Tooltip>
            </div>
            }
        </div>
    )
}

export default Water