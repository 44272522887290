import React from 'react';
import Dashboard from './Tabs/Dashboard';
import Rooms from './Tabs/Rooms';
import Devices from './Tabs/Devices';
import Credentials from './Tabs/Credentials';
import Topics from './Tabs/Topics';
import Schedule from './Tabs/Schedule';
import Subscription from './Tabs/Subscription';
import Profile from './Tabs/Profile';

const Content = (props) => {

  return (
    <React.Fragment>
        {(props.activeTab === "Dashboard") && <Dashboard data={props.data} />}
        {(props.activeTab === "Rooms") && <Rooms />}
        {(props.activeTab === "Devices") && <Devices />}
        {(props.activeTab === "Credentials") && <Credentials />}
        {(props.activeTab === "Topics") && <Topics />}
        {(props.activeTab === "Schedule") && <Schedule />}
        {/* {(props.activeTab === "Subscription") && <Subscription />} */}
        {(props.activeTab === "Profile") && <Profile data={props.data} />}
    </React.Fragment>
  )
}

export default Content