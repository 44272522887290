import React from "react";
import useInput from "../Hooks/useInput";
import useRequest from "../Hooks/useRequest";

const ChangePassword = (props) => {
  const [alertMsg, setAlertMsg] = React.useState([]);
  const [passwordsMatched, setPasswordsMatched] = React.useState(true);

  const {
    value: currentPassword,
    isValid: enteredCurrentPasswordIsValid,
    hasError: currentPasswordInputHasError,
    valueChangeHandler: currentPasswordChangeHandler,
    inputBlurHandler: currentPasswordBlurHandler,
    reset: resetCurrentPassword
  } = useInput((value) => value.trim() !== "");

  const currentPasswordInputClasses = currentPasswordInputHasError
    ? "form-control is-invalid shadow-none"
    : "form-control shadow-none";

  const {
    value: newPassword,
    isValid: enteredNewPasswordIsValid,
    hasError: newPasswordInputHasError,
    valueChangeHandler: newPasswordChangeHandler,
    inputBlurHandler: newPasswordBlurHandler,
    reset: resetNewPassword
  } = useInput((value) => value.length >= 8);

  const newPasswordInputClasses = newPasswordInputHasError
    ? "form-control is-invalid shadow-none"
    : "form-control shadow-none";

  const {
    value: confirmNewPassword,
    isValid: enteredConfirmNewPasswordIsValid,
    hasError: confirmNewPasswordInputHasError,
    isTouched: isCnfPasswordTouched,
    valueChangeHandler: confirmNewPasswordChangeHandler,
    inputBlurHandler: confirmNewPasswordBlurHandler,
    reset: resetCnfNewPassword
  } = useInput((value) => value.trim() !== "");

  const confirmNewPasswordInputClasses = confirmNewPasswordInputHasError
    ? "form-control is-invalid shadow-none"
    : "form-control shadow-none";

  React.useEffect(() => {
    if (isCnfPasswordTouched) {
      if (newPassword !== confirmNewPassword) {
        setPasswordsMatched(false);
      } else {
        setPasswordsMatched(true);
      }
    }
  }, [isCnfPasswordTouched, newPassword, confirmNewPassword]);

  const validForm =
    enteredCurrentPasswordIsValid &&
    enteredNewPasswordIsValid &&
    enteredConfirmNewPasswordIsValid &&
    passwordsMatched;

  const body = {
    current_password: currentPassword,
    new_password: newPassword,
  };

  const { data, isPending, error, fetchData } = useRequest(body);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData("POST", "/auth/users/set_password/", body);
  };

  React.useEffect(() => {
    if (isPending !== null && isPending === true) {
      setAlertMsg({
        class: "primary",
        message: "Please wait...",
      });
    }
    if (isPending === false) {
      setAlertMsg("");
      if (error !== null) {
        if (error.status === 400) {
          if ("current_password" in error.data) {
            setAlertMsg({
              class: "danger",
              message: "Oops! current password is not valid.",
            });
            resetCurrentPassword();
          } else if ("new_password" in error.data) {
            setAlertMsg({
              class: "danger",
              message: error.data["new_password"][0],
            });
            resetNewPassword();
            resetCnfNewPassword();
          }
        } else if (
          error.name === "TypeError" &&
          error.message === "Failed to fetch"
        ) {
          setAlertMsg({
            class: "danger",
            message: "Oops! Server not responding please try again.",
          });
        } else {
          setAlertMsg({
            class: "danger",
            message: "Oops! Something went wrong",
          });
        }
      }
      if (data !== null) {
        if (data.status === 204) {
          setAlertMsg({
            class: "success",
            message: "Password changed successfully!",
          });
          resetCurrentPassword();
          resetNewPassword();
          resetCnfNewPassword();
        }
      }
    }
  }, [props, data, isPending, error, setAlertMsg]);

  return (
    <form
      className="add-form"
      style={{ maxWidth: "380px" }}
      method="POST"
      onSubmit={handleSubmit}
    >
      <h4>Change Password</h4>
      <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
      <div className="form-group">
        <label htmlFor="roomName">Current Password</label>
        <input
          type="password"
          className={currentPasswordInputClasses}
          id="current_password"
          placeholder="Your current password..."
          onChange={currentPasswordChangeHandler}
          onBlur={currentPasswordBlurHandler}
          value={currentPassword}
        />
        {currentPasswordInputHasError && (
          <span className="text-danger">Please enter valid password.</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="info">New Password</label>
        <input
          type="password"
          className={newPasswordInputClasses}
          id="new_password"
          placeholder="New password..."
          onChange={newPasswordChangeHandler}
          onBlur={newPasswordBlurHandler}
          value={newPassword}
        />
        {newPasswordInputHasError && (
          <span className="text-danger">Please enter valid password.</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="info">Confirm New Password</label>
        <input
          type="password"
          className={confirmNewPasswordInputClasses}
          id="confirm_new_password"
          placeholder="Confirm new password..."
          onChange={confirmNewPasswordChangeHandler}
          onBlur={confirmNewPasswordBlurHandler}
          value={confirmNewPassword}
        />
        {confirmNewPasswordInputHasError ? (
          <span className="text-danger">Confirm Password is required.</span>
        ) : (
          !passwordsMatched && (
            <span className="text-danger">Passwords not matched.</span>
          )
        )}
      </div>
      <div className="form-group">
        <button
          type="submit"
          className={`btn btn-primary btn-sm ${!validForm && "disabled"}`}
          style={{ float: "right" }}
        >
          Change Password
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
