import React from 'react';
import '../css/Adminpage.css';
import Content from './Content';
import Sidemenu from './Sidemenu';

const Admin = (props) => {

  const [activeTab, setActiveTab] = React.useState('Dashboard');

  const setContent = (tabName) => {
    setActiveTab(tabName);
  }

    return (
      <React.Fragment>
        <Sidemenu activeTab={activeTab} tabSelected={setContent} displaySideMenu={props.displaySideMenu} username={props.username} />
        <Content activeTab={activeTab} data={props.app_data} />
      </React.Fragment>
    )
}

export default Admin