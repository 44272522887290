import React from 'react';
import useInput from '../Hooks/useInput';
import useFetch from '../Hooks/useFetch';

const Login = (props) => {

    const [alertMsg, setAlertMsg] = React.useState([]);
    
    const changeFormType = (value) => {
        props.changeForm(value);
    }

    const {
        value: enteredEmail,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        // reset: resetEmailInput
    } = useInput(value => (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)));

    const emailInputClasses = emailInputHasError ? 'form-control is-invalid' : 'form-control';
    
    const {
        value: enteredPassword,
        isValid: enteredPasswordIsValid,
        hasError: passwordInputHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
        // reset: resetPasswordInput
    } = useInput(value => value.trim() !== '');

    const passwordInputClasses = passwordInputHasError ? 'form-control is-invalid' : 'form-control';

    const validForm = enteredEmailIsValid && enteredPasswordIsValid;

    // Submit Form
    const postData = {
        email: enteredEmail,
        password: enteredPassword
    }

    const { data, isPending, error, submitHandler: submitLogin } = useFetch(postData);
    React.useEffect(() => {
        if(isPending !== null && isPending === true){
            setAlertMsg({
                'class': 'primary',
                'message': 'Please wait...'
            });
        }
        if(isPending === false){
            setAlertMsg('');
            if(error !== null){
                if(error === '400'){
                    setAlertMsg({
                        'class': 'danger',
                        'message': 'Invalid Credentials.'
                    });
                }
                if(error === 'Failed to fetch'){
                    setAlertMsg({
                        'class': 'danger',
                        'message': 'Oops! Server not responding please try again.'
                    });
                }
            }
            if(data !== null){
                setAlertMsg({
                    'class': '',
                    'message': ''
                });
                props.setLoginState(data.body.auth_token);
            }
        }
    }, [props, data, isPending, error, setAlertMsg]);

    return (
        <div className="container">
          <div className="row">
          <div className="col-sm-8">
            <div className='banner'>
                <center>
                    <img src={require('../../img/logo.png')} alt='logo' style={{width: '40%', height: 'auto'}} />
                    <h1>SmartHome</h1>
                    <h6>Control your home with smart switches.</h6>
                    
                </center>
            </div>
          </div>
            <div className="col-sm-4">
                <form autoComplete='off' className='account-form' method='POST' action='/auth/token/login/' token='false' onSubmit={submitLogin}>
                    <h2 className=''>Login</h2>
                    <p className='my-2'>Don't have an account? <span className='text-primary account-link' onClick={() => changeFormType('register')}>Register</span></p>
                    <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
                    <div className="form-group">
                        <label htmlFor="email">Email address:</label>
                        <input type="email" className={emailInputClasses} placeholder="Enter email" id="email" onChange={emailChangeHandler} onBlur={emailBlurHandler} value={enteredEmail} />
                        {emailInputHasError && (
                            <span className="text-danger">Please enter a valid email.</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="pwd">Password:</label>
                        <input autoComplete='off' type="password" className={passwordInputClasses} placeholder="Enter password" id="pwd" onChange={passwordChangeHandler} onBlur={passwordBlurHandler} value={enteredPassword} />
                        {passwordInputHasError && (
                            <span className="text-danger">Password is required.</span>
                        )}
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                        <div className="form-group form-check">
                            <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" /> Remember me
                            </label>
                        </div>
                        <span className='text-primary account-link' onClick={() => changeFormType('forgot_password')}>Forgot Password</span>
                    </div>
                    <button type="submit" className="btn btn-primary my-1" disabled={!validForm}>Login</button>
                </form>
            </div>
          </div>
        </div>
    )
}

export default Login
