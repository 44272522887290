import React from "react";
import useInput from "../Hooks/useInput";
import useFetch from "../Hooks/useFetch";

const ForgotPassword = (props) => {
  const [alertMsg, setAlertMsg] = React.useState([]);

  const changeFormType = React.useCallback((value) => {
    props.changeForm(value);
  }, [props]);

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    // reset: resetEmailInput
  } = useInput((value) =>
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      value
    )
  );

  const emailInputClasses = emailInputHasError
    ? "form-control is-invalid"
    : "form-control";

  const validForm = enteredEmailIsValid;

  // Submit Form
  const postData = {
    email: enteredEmail,
  };

  const {
    isPending,
    error,
    submitHandler: submitLogin,
  } = useFetch(postData);

  React.useEffect(() => {
    if (isPending !== null && isPending === true) {
      setAlertMsg({
        class: "primary",
        message: "Please wait...",
      });
    }
    if (isPending === false) {
      setAlertMsg("");
      if(error !== null){
        if (error === "400") {
          setAlertMsg({
            class: "danger",
            message: "Oops! email not found.",
          });
        }else if (error === "Failed to fetch") {
          setAlertMsg({
            class: "danger",
            message: "Oops! Server not responding please try again.",
          });
        } else {
          setAlertMsg({
            class: "success",
          message: "Email sent successfully."
          });
          setTimeout(() => {
            changeFormType("login");
          }, 4000);
        }
      }
    }
  }, [isPending, error, setAlertMsg, changeFormType]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <div className="banner">
            <center>
              <img
                src={require("../../img/logo.png")}
                alt="logo"
                style={{ width: "40%", height: "auto" }}
              />
              <h1>SmartHome</h1>
              <h6>Control your home with smart switches.</h6>
            </center>
          </div>
        </div>
        <div className="col-sm-4" style={{alignContent: 'center'}}>
          <form
            autoComplete="off"
            className="account-form"
            method="POST"
            action="/auth/users/reset_password/"
            token="false"
            onSubmit={submitLogin}
          >
            <h2 className="">Forgot Password</h2>
            <p className="my-2">
              Don't want to reset?{" "}
              <span
                className="text-primary account-link"
                onClick={() => changeFormType("login")}
              >
                Login
              </span>
            </p>
            <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
            <div className="form-group">
              <label htmlFor="email">Email address:</label>
              <input
                type="email"
                className={emailInputClasses}
                placeholder="Enter email"
                id="email"
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                value={enteredEmail}
              />
              {emailInputHasError && (
                <span className="text-danger">Please enter a valid email.</span>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-primary my-1"
              disabled={!validForm}
            >
              Send Reset Link
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
