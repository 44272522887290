import React from 'react';
import useInput from '../../Hooks/useInput';
import useFetch from '../../Hooks/useFetch';
import useAuthFetch from '../../Hooks/useAuthFetch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const AddSchedule = (props) => {
  
dayjs.extend(utc);
dayjs.extend(timezone);

    const [alertMsg, setAlertMsg] = React.useState([]);
    const {data:roomData, fetchData:fetchRoomData} = useAuthFetch();
    const {data:deviceData, fetchData:fetchDeviceData} = useAuthFetch();
    const [action, setAction] = React.useState('true');
    const [days, setDays] = React.useState([]);
    const [time, setTime] = React.useState(dayjs(new Date()));
    const [roomName, setRoomName] = React.useState('');
    const [deviceName, setDeviceName] = React.useState('');
    const [scheduleName, seetScheduleName] = React.useState('');
    const [scheduleInfo, seetScheduleInfo] = React.useState('');
  
    const handleChangeAction = (event) => {
      setAction(event.target.value);
    };

  const handleChangeDay = (event) => {
      const selectedDay = Number(event.target.value);
      if (!days.includes(selectedDay)) {
          setDays((prevDays) => {
              return [...prevDays, selectedDay];
          });
      }
  };
    
    React.useEffect(() => {
      fetchRoomData('GET', '/app/room/');
    }, [fetchRoomData]);
    
    const {
      value: roomId,
      isValid: enteredRoomIdIsValid,
      hasError: roomIdInputHasError,
      valueChangeHandler: roomIdChangeHandler,
      inputBlurHandler: roomIdBlurHandler,
    } = useInput(value => value.trim() !== '');
  
    const roomIdInputClasses = roomIdInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
    
    const {
      value: deviceId,
      isValid: enteredDevicedIsValid,
      hasError: deviceIdInputHasError,
      valueChangeHandler: deviceIdChangeHandler,
      inputBlurHandler: deviceIdBlurHandler,
    } = useInput(value => value.trim() !== '');
  
    const deviceIdInputClasses = deviceIdInputHasError ? 'form-control is-invalid shadow-none' : 'form-control shadow-none';
  

    const roomChangeHandler = (e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const roomName = selectedOption.getAttribute('roomName');
      setRoomName(roomName);
      roomIdChangeHandler(e);
    }
    
    const deviceChangeHandler = (e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const deviceName = selectedOption.getAttribute('deviceName');
      setDeviceName(deviceName);
      deviceIdChangeHandler(e);
    }
    
    const validForm = enteredDevicedIsValid;

    
    React.useEffect(() => {
      if(enteredRoomIdIsValid){
        fetchDeviceData('GET', '/app/device_by_room/?roomId='+roomId);
      }

    }, [enteredRoomIdIsValid, roomId, fetchDeviceData]);

    React.useEffect(() => {
      if(enteredRoomIdIsValid && enteredDevicedIsValid){
        seetScheduleName(roomName+' '+deviceName);
        seetScheduleInfo(((action === 'true') ? 'Turn On' : 'Trun Off')+' '+deviceName+' of '+roomName);
      }
    }, [enteredRoomIdIsValid, enteredDevicedIsValid, roomName, deviceName, action]);
  
    const postData = {
      name: scheduleName,
      info: scheduleInfo,
      device: deviceId,
      turn_on: action,
      repeat_days: days.join(','),
      repeat_time: dayjs(time).tz(dayjs.tz.guess()).format('HH:mm:ss')

    }
    
    const { data, isPending, error, submitHandler: submitForm } = useFetch(postData);
  
    React.useEffect(() => {
      if(isPending !== null && isPending === true){
          setAlertMsg({
              'class': 'primary',
              'message': 'Please wait...'
          });
      }
      if(isPending === false){
          setAlertMsg('');
          if(error !== null){
              if(error === 'Failed to fetch'){
                  setAlertMsg({
                      'class': 'danger',
                      'message': 'Oops! Server not responding please try again.'
                  });
              } else {
                setAlertMsg({
                    'class': 'danger',
                    'message': 'Oops! Something went wrong'
                });
              }
          }
          if(data !== null){
            if(data.status === 201){
              props.displayMe(false);
              setAlertMsg({
                'class': '',
                'message': ''
              });
            }
          }
      }
    }, [props, data, isPending, error, setAlertMsg]);
  
  return (
    <form className='add-form' method='POST' action='/app/schedule/add/' token='true' onSubmit={submitForm}>
        <span className={`text-${alertMsg.class}`}>{alertMsg.message}</span>
        <div style={{display: 'flex', justifyContent: 'row'}}>
          <div className="form-group" style={{width: '49%'}}>
              <label htmlFor="roomId">Select Room</label>
              <select name="roomId" id="roomId" className={roomIdInputClasses} onChange={roomChangeHandler} onBlur={roomIdBlurHandler} value={roomId}>
                  <option value="">Select...</option>
                  {(roomData !== null) && roomData.body.results.map((item, index) => {
                      return (
                          <option key={index} value={item.id} roomName={item.name}>{item.name}</option>
                      )
                  })}  
              </select>
              {roomIdInputHasError && (
                  <span className="text-danger"><br />Please select room.</span>
              )}
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div className="form-group" style={{width: '49%'}}>
              <label htmlFor="deviceId">Select Device</label>
              <select name="deviceId" id="deviceId" className={deviceIdInputClasses} onChange={deviceChangeHandler} onBlur={deviceIdBlurHandler} value={deviceId}>
                  <option value="">Select...</option>
                  {(deviceData !== null) && deviceData.body.results.map((item, index) => {
                      return (
                          <option key={index} value={item.id} deviceName={item.name}>{item.name}</option>
                      )
                  })}  
              </select>
              {roomIdInputHasError && (
                  <span className="text-danger"><br />Please select device.</span>
              )}
          </div> 
        </div>
        <div className="form-group">
            <label htmlFor="scheduleName">Schedule Name</label>
            <input type="text" className='form-control shadow-none' id='scheduleName' value={scheduleName} />
        </div>
        <div className="form-group">
            <label htmlFor="info">Description</label>
            <input type="text" className='form-control shadow-none' id='info' value={scheduleInfo} />
        </div>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Action</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={action}
            onChange={handleChangeAction}
          >
            <FormControlLabel value="true" control={<Radio />} label="Turn On" />
            <FormControlLabel value="false" control={<Radio />} label="Turn Off" />
          </RadioGroup>
        </FormControl>
        
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label="Choose Time">
              <TimePicker
                value={time}
                onChange={(newValue) => setTime(newValue)}
              />
              </DemoItem>
          </LocalizationProvider>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose days if you want to repeat it.</FormLabel>
              <FormGroup aria-label="position" row onChange={handleChangeDay}>
                <FormControlLabel value="0" control={<Checkbox size="small" />} label="Sunday" labelPlacement="bottom" />
                <FormControlLabel value="1" control={<Checkbox size="small" />} label="Monday" labelPlacement="bottom" />
                <FormControlLabel value="2" control={<Checkbox size="small" />} label="Tuesday" labelPlacement="bottom" />
                <FormControlLabel value="3" control={<Checkbox size="small" />} label="Wednesday" labelPlacement="bottom" />
                <FormControlLabel value="4" control={<Checkbox size="small" />} label="Thursday" labelPlacement="bottom" />
                <FormControlLabel value="5" control={<Checkbox size="small" />} label="Friday" labelPlacement="bottom" />
                <FormControlLabel value="6" control={<Checkbox size="small" />} label="Saturday" labelPlacement="bottom" />
              </FormGroup>
          </FormControl>
        </div>
        <div className="form-group">
            <button type="submit" className={`btn btn-primary btn-sm ${(!validForm && 'disabled')}`} style={{float: 'right', marginTop: '10px'}}>Add Schedule</button>
        </div>
    </form>
  )
}

export default AddSchedule