import React from 'react';
import '../../css/Content.css';
import AddRoom from './AddRoom';
import useAuthFetch from '../../Hooks/useAuthFetch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Authorization from './Authorization';
import Tooltip from '@mui/material/Tooltip';
import ViewRoomSetting from './ViewRoomSetting';

const Rooms = () => {

  const {data, isPending, error: fetchError, fetchData} = useAuthFetch();
  const {data:topicData, fetchData:fetchTopic} = useAuthFetch();
  const [displayForm, setDisplayForm] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [selectedTopic, setSelectedTopic] = React.useState([]);
  const [displayAuthorization, setDisplayAuthorization] = React.useState(false);
  const [displayRoomSetting, setDisplayRoomSetting] = React.useState(false);
  const [currentRoom, setCurrentRoom] = React.useState(null);

  const handleDisplayRoomSetting = (room, display) => {
    setCurrentRoom(room)
    setDisplayRoomSetting(display);
  }

  
  const handleDisplayAuthorization = (topic, display) => {
    if (topic.username !== undefined) {
      const tp = 'eye3network/'+topic.username+'/'+topic.name+'/%23';
      setSelectedTopic([]);
      fetchTopic('GET', '/mqtt/get_topic/?name='+tp);
    }
    setDisplayAuthorization(display)
  }

  React.useEffect(() => {
    if(topicData !== null){
      setSelectedTopic(topicData.body);
      setDisplayAuthorization(true);
    }
  }, [topicData]);

  const displayAddForm = () => {
    setDisplayForm(false)
    setRefresh(refresh+1);
  }

  React.useEffect(() => {
      fetchData('GET', '/app/room/?page='+currentPage);
  }, [refresh, fetchData, currentPage]);

  const deleteRoom = (id) => {
    if(window.confirm('Are you sure?')){
      fetchData('DELETE', '/app/room/delete/'+id+'?page='+currentPage);
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  React.useEffect(() => {
    if (data != null) {
      setTotalPages(Math.ceil(data.body.count / 10));
    }
  }, [data]);

  return (
    <div className='content-page'>
        {!displayRoomSetting && !displayAuthorization && <div className='content-heading d-flex justify-content-between'>
            <div>
              {!displayForm ? 
                <div>
                  <button className='btn btn-primary btn-sm' onClick={() => {setDisplayForm(true)}}><i className="fa fa-plus" aria-hidden="true"></i> Add Room</button>
                  <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => {setRefresh(refresh+1)}}><span>&nbsp;<i className='fa fa-refresh'></i>&nbsp;</span></button>
                </div> : 
                  <button className='btn btn-outline-primary btn-sm' onClick={() => {setDisplayForm(false)}}><span>&nbsp;<i className="fa fa-arrow-circle-o-left"></i>&nbsp;</span></button>
              }
            </div>
            <h4>{displayForm ? 'New Room' : 'Rooms'}</h4>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        </div>
        }
        
        <div className='content-box'>
          {displayRoomSetting ? <ViewRoomSetting displayMe={handleDisplayRoomSetting} room={currentRoom} /> : displayAuthorization ? <Authorization topic={selectedTopic} displayMe={(topic, display) => handleDisplayAuthorization(topic, display)} /> : displayForm ?
            <AddRoom displayMe={displayAddForm} /> :
            <div>
              <div className='alert-box'>
                {isPending && <span>Please wait...</span>}
                {fetchError && <span className='text-danger'>Oops! Something went wrong.</span>}
              </div>
              <div style={{overflowX: 'auto'}}>  
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>ROOM</th>
                      <th>DESCRIPTION</th>
                      <th>AUTHORIZE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data !== null) && data.body.results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.info}</td>
                          <td><Button variant="outlined" size='small' onClick={() => handleDisplayAuthorization(item, false)}>Check</Button></td>
                          <td>
                            <IconButton onClick={() => {deleteRoom(item.id)}} disabled={item.is_default} color='error'><Tooltip title='Delete'><DeleteIcon /></Tooltip></IconButton>
                            <IconButton onClick={() => {handleDisplayRoomSetting(item, true)}} color='primary'><Tooltip title='View Settings'><SettingsIcon /></Tooltip></IconButton>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Stack spacing={2}>
                <Pagination count={totalPages} color="primary" value={currentPage} onChange={handlePageChange} />
              </Stack>
            </div>
          }
        </div>
    </div>
  )
}

export default Rooms