import React from 'react';
import ChangePassword from '../../Account/ChangePassword';

const Profile = (props) => {
  return (
    <div className='content-page'>
      <h4>Hello {props.data.first_name}!</h4>
      <div className='content-box' style={{marginTop: '20px'}}>
        <ChangePassword />
      </div>
    </div>
  )
}

export default Profile