import React from 'react';
import AuthContext from '../Store/authContext';

const useFetch = (postData) => {

    const secure = localStorage.getItem('secure');
    const  link = localStorage.getItem('url');
    const url = (secure === 'true') ? 'https://'+link : 'http://'+link;

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            console.log(cookies);
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    
    const csrftoken = getCookie('csrftoken');

    const [isPending, setIsPending] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const authCtx = React.useContext(AuthContext);


    const submitHandler = async (event) => {
        event.preventDefault();
        setIsPending(true);

        const requestMethod = event.target.getAttribute('method');
        const page = event.target.getAttribute('action');
        const pageUrl = url + page;
        const tokenRequired = event.target.getAttribute('token');

        let headers;
        if(tokenRequired === 'true'){
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
                'Authorization': 'Token '+ authCtx.token
            }
        }else{
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        const requestOptions = {
            method: requestMethod,
            headers,
            body: JSON.stringify(postData)
        };

        await fetch(pageUrl, requestOptions)
        .then(async function(response) {
            if (!response.ok) {
                throw Error(response.status);
            } else {
                setIsPending(false);
                const data = await response.json();
                return ({ status: response.status, body: data });
            }
        }).then(function(obj) {
            setData(obj);
            setError(null);
            setIsPending(false);
        }).catch(function(err) {
            setData(null);
            setError(err.message)
            setIsPending(false);
        });
    };

    return { isPending, error, data, submitHandler };
}

export default useFetch
